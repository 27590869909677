<template>
  <div>
    <FullScreenDialog
      v-model="serviceTypeDialog"
      title="Famiglia"
      :routeFather="pathToGoBack"
      :routeFatherName="pathName"
      :otherGoBackParams="{ params: { filters: this.filters } }"
    >
      <template v-slot:toolbar-extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="tab in serviceTypeTabs"
            :key="tab.key"
          >{{ tab.title }}</v-tab>
        </v-tabs>
      </template>
  
      <template v-slot:content>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tabItem in serviceTypeTabs" :key="tabItem.key">
            <v-card flat>
              <v-card-text class="pa-0">
                <component :is="tabItem.component" :passChange="passChange"></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>
  
      <template v-slot:footer-actions>
        <div>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="closeDialog()"
          >Annulla</v-btn>
          <v-btn
            text
            color="default"
            :disabled="!formValid"
            @click="openConfirmDialog"
          >Salva</v-btn>
        </div>
      </template>
  
  <!-- KEEP COMMENT - ONEWARE-562    
    <template v-slot:header-actions>
        <div>
          <StandardMenu
            close-on-content-click
          >
            <template v-slot:activator="{on, attrs}">
              <v-btn icon v-on="on" v-bind="attrs">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item link>
                  <v-list-item-icon class="mr-3">
                    <v-icon>mdi-printer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>Stampa</v-list-item-content>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-icon class="mr-3">
                    <v-icon color="error">mdi-backspace-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="error--text">Resetta</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </StandardMenu>
        </div>
      </template> -->
    </FullScreenDialog>
      <StandardDialog
        v-model="confirmDialog"
        :dialog-height="null"
        title="Conferma Modifica"
        dialog-width="500px"
      >
        <span>
          Attenzione! Si sta modificando un parametro legato alla fidelizzione. Continuare?
        </span>
        <template v-slot:footer-actions>
          <v-btn color="error" text @click="handleClosure"
            >Chiudi</v-btn
          >
          <v-btn
            color="default"
            text
            @click="submitForm"
          >Conferma</v-btn
        >
        </template>
      </StandardDialog>
      </div>
  </template>
  
  <script>
  import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
  import StandardMenu from '@/components/common/StandardMenu.vue';
  import serviceTypesService from '@/services/serviceTypes/serviceTypes.service.js'
  import serviceTypeForm from '@/services/serviceTypes/serviceType.form.js'
  import GeneralFormTab from '@/components/areas/registries/serviceTypes/form/GeneralFormTab.vue'
  import StandardDialog from "@/components/common/StandardDialog.vue";
  export default {
    name: "ServiceTypesRegistryEditForm",
    components: {
      FullScreenDialog,
      StandardMenu,
      GeneralFormTab,
      StandardDialog
    },
    data: function () {
      return {
        id: undefined,
        tab: null,
        formValid: false,
        loadingServiceType: false,
        serviceTypeDialog: this.openDialog,
        serviceTypeTabs: [
          {
            key: 0,
            title: "Generale",
            name: "general",
            component: "GeneralFormTab"
          }
        ],
        serviceType: undefined,
        originalUrl: window.location.pathname,
        confirmDialog: false,
        change: false,
      };
    },
    mounted() {
      this.id = this.$route.params.id
      if(this.id) {
        this.fetchServiceType()
      }
      this.changeTabBasedOnProp(this.tabName)
      let self = this
      serviceTypeForm.on('update', function(data) {
        self.serviceType = data.serviceType
      })
      self.valid = serviceTypeForm.valid
      serviceTypeForm.on('valid-change', function(valid) {
        self.formValid = valid
      })
    },
    props: {
      openDialog: {
        type: Boolean,
        default: false,
      },
      pathToGoBack: {
        type: String,
        default: "/registries",
      },
      tabName: {
        type: String,
        default: 'general'
      },
      pathName: {
        type: String,
      },
      filters: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      changeTabBasedOnProp(tabName) {
        for(let i = 0; i < this.serviceTypeTabs.length; i++) {
          if(this.serviceTypeTabs[i].name == tabName) {
            this.tab = i
            break
          }
        }
      },
      submitForm() {
        serviceTypesService.update(this.serviceType).then((result) => {
          this.close()
        })
      },
      closeDialog() {
        if (this.pathToGoBack) {
          this.close()
        } else {
          this.serviceTypeDialog = false
          this.$emit('update:open-dialog', false)
        }
      },
      close() {
        if(!!this.pathName)
          this.$router.push({name: this.pathName, params: { filters: this.filters }})
        else
          this.$router.push({path:this.pathToGoBack})
      },
      fetchServiceType() {
        this.loadingServiceType = true
        serviceTypeForm.resetServiceType()
        serviceTypesService.get(this.id).then((serviceType) => {
          this.loadingServiceType = false
          serviceTypeForm.updateObject(serviceType)
        })
      },
      openConfirmDialog() {
        if(!(!!this.serviceType.mediumFidelityDays == !!this.serviceType.highFidelityDays))
          this.$delegates.snackbar( "Inserire entrambi i valori di fidelizzazione")
        else if(Number(this.serviceType.mediumFidelityDays) <= Number(this.serviceType.highFidelityDays))
          this.$delegates.snackbar( "I valori di fidelizzazione sono errati")
        else if (Number(this.serviceType.highFidelityDays) > 363 || Number(this.serviceType.mediumFidelityDays) > 364)
          this.$delegates.snackbar( "I valori di fidelizzazione sono errati")
        else if(this.change == true)
          this.confirmDialog = true
        else
          this.submitForm()
      },
      handleClosure() {
        this.confirmDialog = false
      },
      passChange (){
        this.change = true
      }
    },
    watch: {
      openDialog(newVal) {
        this.serviceTypeDialog = newVal;
      },
      tab(newVal) {
        try {
          window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.serviceTypeTabs[newVal].name);
        } catch(err) {
          console.log('try setting the tab name but something goes wrong')
        }
      }
    },
  };
  </script>