import eventsHandler from '@/services/common/eventsHandler.js'

class serviceTypeForm extends eventsHandler {
  constructor() {
    super()
    this._serviceType = {}
    this._valid = false
  }

  get serviceType() {
    const serviceType = {
      ...this._serviceType
    }
    return serviceType
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._serviceType.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetServiceType() {
    this._serviceType = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {serviceType: {}})
  }

  updateField(key, value) {
    this._serviceType[key] = value
    this._executeCallbacksOf('update', {serviceType: this._serviceType})
    this._executeCallbacksOf('update-' + key, {key: this._serviceType[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(serviceType) {
    this._serviceType = serviceType
    this._executeCallbacksOf('update', {serviceType: this._serviceType})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new serviceTypeForm()